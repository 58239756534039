import OnboardingLayout from "layouts/OnboardingLayout";
import OnboardingApplicationEvaluationCard from "modules/onboarding/components/OnboardingApplicationEvaluationCard";

const OnboardingApplicationEvaluationPage = () => {
  return (
    <OnboardingLayout>
      <OnboardingApplicationEvaluationCard />
    </OnboardingLayout>
  );
};

export default OnboardingApplicationEvaluationPage;
