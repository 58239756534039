import { ChatTeardropDots, MagnifyingGlass } from "@phosphor-icons/react";
import { useChatWidget } from "components/ChatWidget";
import useUser from "modules/user/queries/useUser";
import { FC } from "react";
import VirtualButton from "ui/inputs/VirtualButton";
import { Heading1, Heading4, Paragraph, Span, Strong } from "ui/typography";

import OnboardingCard from "./OnboardingCard";

type Props = {
  className?: string;
};

const OnboardingApplicationEvaluationCard: FC<Props> = ({ className }) => {
  const chat = useChatWidget();
  const user = useUser();

  return (
    <OnboardingCard className={className}>
      <OnboardingCard.Header>
        <Heading1 className="text-xl font-bold">We are manually reviewing your business</Heading1>
      </OnboardingCard.Header>

      <OnboardingCard.Body>
        <div className="mb-6 rounded-lg bg-grey-50 p-6">
          <Heading4 className="mb-3 flex items-center gap-x-1 text-sm font-medium text-grey-600">
            <MagnifyingGlass /> Reasons why we review a business:
          </Heading4>
          <ul className="list-inside list-disc text-sm text-grey-500">
            <li>The business has no online stores (e.g. Shopify, Amazon)</li>
            <li>The business is pre-revenue</li>
            <li>Fraud prevention</li>
          </ul>
        </div>

        <Paragraph className="text-sm text-grey-600">
          When our review is complete in{" "}
          <Strong className="font-medium">1 - 3 business days</Strong>, we will email you with the
          results at <Strong className="font-medium">{user.emailAddress}</Strong>. Thank you for
          your patience.
        </Paragraph>
      </OnboardingCard.Body>

      <OnboardingCard.Footer className="justify-start">
        <Span className="text-sm text-grey-500">Have questions?</Span>
        <VirtualButton
          className="flex items-center gap-x-1"
          onClick={() => chat.message("I have a question about my business application.")}
        >
          <ChatTeardropDots className="text-purple-500" size={14} />
          <Span className="text-xs text-purple-500">Contact support</Span>
        </VirtualButton>
      </OnboardingCard.Footer>
    </OnboardingCard>
  );
};

export default OnboardingApplicationEvaluationCard;
