import OnboardingLayout from "layouts/OnboardingLayout";
import useBusiness from "modules/business/queries/useBusiness";
import OnboardingGetStartedContent from "modules/onboarding/components/OnboardingGetStartedContent";
import useGetStartedMutation from "modules/onboarding/mutations/useGetStartedMutation";
import { Navigate } from "react-router-dom";

const OnboardingGetStartedPage = () => {
  const { mutate: getStarted, isPending } = useGetStartedMutation({
    onSuccess: () => {
      window.location.href = "/"; // Intended to force a refresh.
    },
  });

  const { unitCoCustomerId, status } = useBusiness();

  // Calling `getStarted` without a `unitCoCustomerId` results in an error.
  // We redirect back to `/onboarding` to let `OnboardingNavigate` handle the re-routing logic.
  if (!unitCoCustomerId || status !== "Active") {
    return <Navigate to="/onboarding" />;
  }

  return (
    <OnboardingLayout className="bg-purple-50" showBackgroundImage={false}>
      <OnboardingGetStartedContent onGetStarted={() => getStarted()} isLoading={isPending} />
    </OnboardingLayout>
  );
};

export default OnboardingGetStartedPage;
