import { ErrorBoundary } from "@sentry/react";
import OnboardingLayout from "layouts/OnboardingLayout";
import { useIsCurrentBusinessMemberOnboarded } from "modules/business-members/queries/businessMemberQueryHooks";
import LoadingPage from "modules/loading/pages/LoadingPage";
import OnboardingBookATimeCard from "modules/onboarding/components/OnboardingBookATimeCard";
import OnboardingConnectPlaidCard from "modules/onboarding/components/OnboardingConnectPlaidCard";
import OnboardingErrorCard from "modules/onboarding/components/OnboardingErrorCard";
import OnboardingApplicationEvaluationPage from "pages/onboarding/OnboardingApplicationEvaluationPage";
import OnboardingBankApplicationPage from "pages/onboarding/OnboardingBankApplicationPage";
import OnboardingConnectStoresPage from "pages/onboarding/OnboardingConnectStoresPage";
import OnboardingGetStartedPage from "pages/onboarding/OnboardingGetStartedPage";
import OnboardingPersonalInfoPage from "pages/onboarding/OnboardingPersonalInfoPage";
import { Suspense } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";

import OnboardingNavigate from "./OnboardingNavigate";

const OnboardingRouter = () => {
  const [searchParams] = useSearchParams();

  const isOnboarded = useIsCurrentBusinessMemberOnboarded();

  const shouldRedirect = isOnboarded && !searchParams.get("forceOnboarding");
  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

  return (
    <ErrorBoundary
      fallback={() => (
        <OnboardingLayout>
          <OnboardingErrorCard />
        </OnboardingLayout>
      )}
    >
      <Suspense fallback={<LoadingPage location={OnboardingRouter.name} />}>
        <Routes>
          <Route
            path="/connect-accounts-and-cards"
            element={
              <OnboardingLayout progressBarPercentage={0}>
                <OnboardingConnectPlaidCard />
              </OnboardingLayout>
            }
          />
          <Route
            path="/book-a-time"
            element={
              <OnboardingLayout progressBarPercentage={10}>
                <OnboardingBookATimeCard />
              </OnboardingLayout>
            }
          />
          <Route path="/connect-stores" element={<OnboardingConnectStoresPage />} />
          <Route path="/bank-application" element={<OnboardingBankApplicationPage />} />
          <Route path="/personal-info" element={<OnboardingPersonalInfoPage />} />
          <Route path="/get-started" element={<OnboardingGetStartedPage />} />
          <Route path="/application-in-review" element={<OnboardingApplicationEvaluationPage />} />
          <Route path="*" element={<OnboardingNavigate />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default OnboardingRouter;
