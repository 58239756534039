import { useSuspenseQuery } from "@tanstack/react-query";
import { useBankApplication } from "modules/bank-application/queries/useBankApplicationQueryOptions";
import { useCurrentBusinessMember } from "modules/business-members/queries/businessMemberQueryHooks";
import useBusiness from "modules/business/queries/useBusiness";
import useRutterConnectionsQueryOptions from "modules/connected-stores/queries/useRutterConnectionsQueryOptions";
import useShopifyConnectionsQueryOptions from "modules/connected-stores/queries/useShopifyConnectionsQueryOptions";
import useIsQualifiedForOnboardingConnectPlaid from "modules/onboarding/hooks/useIsQualifiedForOnboardingConnectPlaid";
import { useHasPlaidConnections } from "modules/plaid/queries/plaidConnectionsQueryHooks";
import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import BankApplicationRep from "reps/BankApplicationRep";
import useIsAllowedToConnectStores from "utils/permissions/useIsAllowedToConnectStores";

const useHasShopifyOrAmazonConnection = () => {
  const { data: amazonConnections } = useSuspenseQuery({
    ...useRutterConnectionsQueryOptions(),
    select: (rutterConnections) =>
      rutterConnections.filter((rutterConnection) => rutterConnection.platformName === "AMAZON"),
  });

  const { data: shopifyConnections } = useSuspenseQuery(useShopifyConnectionsQueryOptions());

  return amazonConnections.length + shopifyConnections.length > 0;
};

const useDetermineOnboardingStepPath = () => {
  const bankApplication = useBankApplication();

  const isQualifiedForOnboardingConnectPlaid = useIsQualifiedForOnboardingConnectPlaid();
  const hasPlaidConnections = useHasPlaidConnections();

  const businessMember = useCurrentBusinessMember();
  const business = useBusiness();

  const hasShopifyOrAmazonConnection = useHasShopifyOrAmazonConnection();
  const isAllowedToConnectStores = useIsAllowedToConnectStores();

  return useMemo(() => {
    switch (business.status) {
      case "Archived":
      case "Rejected":
        throw new Error(
          "Archived & Rejected `business.status` is handled in `AuthenticatedRouter`."
        );
      case "PendingReview":
        if (!bankApplication || bankApplication?.status === BankApplicationRep.Status.FormCreated) {
          if (!hasPlaidConnections && isQualifiedForOnboardingConnectPlaid) {
            // This flow will lead to the bank application.
            return "/onboarding/connect-accounts-and-cards";
          }
        }

        if (
          isAllowedToConnectStores &&
          !hasShopifyOrAmazonConnection &&
          bankApplication?.status === BankApplicationRep.Status.Approved
        ) {
          return "/onboarding/connect-stores";
        }

        return "/onboarding/bank-application";

      case "Active":
        // NB(alex): There is a rare edge case where the user lands on the get started page but doesn't have a `unitCoCustomerId` yet.
        // When this happens, we want to redirect them to the connect stores page to give our server some time to populate this field.
        // Then, when they click "Next", they will land back on the get started page, and hopefully `unitCoCustomerId` is populated by then.
        // If the `unitCoCustomerId` never populates, there is probably a deeper issue happening.
        if (!business.unitCoCustomerId) {
          return "/onboarding/connect-stores";
        }

        // Invited users only
        const isBusinessOwner =
          businessMember && business.ownerUserGuid === businessMember.userGuid;
        const hasDateOfBirth = Boolean(businessMember?.dateOfBirth);
        if (!isBusinessOwner && !hasDateOfBirth) {
          return "/onboarding/personal-info";
        }

        return "/onboarding/get-started";
    }
  }, [
    bankApplication,
    hasShopifyOrAmazonConnection,
    hasPlaidConnections,
    businessMember,
    business,
    isAllowedToConnectStores,
    isQualifiedForOnboardingConnectPlaid,
  ]);
};

const OnboardingNavigate = () => {
  const to = useDetermineOnboardingStepPath();
  return <Navigate replace to={to} />;
};

export default OnboardingNavigate;
